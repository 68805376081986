import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

@customElement('video-background')
export class VideoBackground extends LitElement {
  @property()
  mp4 = ''

  @property()
  webm = ''

  @property()
  poster = ''

  static styles = css`
    :host {
      z-index: -5;
      isolation: isolate;
    }

    :host, video {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    }
  `

  protected render() {
    return html`
      <video playsinline autoplay muted loop poster="${ifDefined(this.poster)}">
        <source src="${ifDefined(this.webm)}" type="video/webm">
        <source src="${ifDefined(this.mp4)}" type="video/mp4">
      </video>
    `
  }
}
